// @import "./antd.less";
@import "./var";
@import "./common";
@import "./icon";
@import './animation';
@import "../../../commonStyle/reset";

p,span,div,a{
  word-break: break-word;
}
.keep-all{
  word-break: keep-all !important;
}
.toggle-language{
  cursor: pointer;
  .cu-btn;
  .bg-blue;
  padding: 0 12px;
  font-size: 16px;
}
.my_reset{
  ol, ul, dl {
    margin-top: 0;
    margin-bottom: 0;
  }
}
body,html{
  min-height: 100%;
  min-width: 1180px;
}
body{
  padding-top: 82px;
}

#header{
  z-index: 999;
  display: flex;
  align-items: center;
  box-shadow: 0px 2px 12px 0px
		rgba(9, 9, 9, 0.12);
  background-color: @white;
  min-width: 1200px;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  padding-left: 15px;
  padding-right: 35px;
  transition: all .3s;
  .header-bg{
    align-items: center;
    min-width: 1180px;
    max-width: 1800px;
    width: 100%;
    margin: auto;
    height: 82px;
    display: flex;
    justify-content: space-between;
  }
  .logo{
    height: 41px;
    .b-logo,.w-logo{
      width: auto;
      display: inline-block;
    }
    .w-logo{
      display: none;
    }
  }
  .right-menu{
    position: relative;
    color: #1a1a1a;
    .menu-list{
      position: relative;
      display: flex;
      align-items: center;
      li{
        height: 82px;
        transition: all .3s;
        position: relative;
        > .sub-menu{
          display: none;
          font-size: 14px;
          color: @black;
          min-width: 156px;
          position: absolute;
          background-color: #fff;
          right: 0%;
          top: 82px;
          box-shadow: 0 3px 16px 0 rgba(2, 20, 40, 0.18);
          > .item{
            padding: 16px 10px;
            white-space: nowrap;
            color: #1a1a1a;
            position: relative;
            > .third-menu{
              position: absolute;
              top: 0;
              left: 100%;
              min-width: 156px;
              background-color: #fff;
              box-shadow: 0 3px 16px 0 rgba(2, 20, 40, 0.18);
              display: none;
              >a{
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 16px 10px;
                color: #1a1a1a;
                font-size: 16px;
                &:hover,&.active{
                  color: #fff;
                  background-color: @blue;
                }
              }
            }
            > a{
              font-size: 16px;
              display: flex;
              align-items: center;
              justify-content: space-between;
              color: #1a1a1a;
              .iconfont{
                display: inline-block;
                font-size: 12px;
                transform: rotate(-90deg);
                margin-left: 14px;
              }
              & + span{
                font-size: 14px;
                margin-top: 8px;
              }
            }
            &:hover,&.active{
              background-color: @blue;
              >a{
                color: #fff;
              }
              .third-menu{
                display: block;
              }
            }
          }

        }
        &:hover{
          > .sub-menu{
            display: block;
          }
          > a{
            color: @blue;
            > .title{
              > .icon-arrow-down-bold{
                transform: rotate(180deg);
              }
            }
            .en{
              color: @blue;
            }
          }
        }
        &.active{
          > a{
            color: @blue;
            > .title{
              > .icon-arrow-down-bold{
                transform: rotate(180deg);
              }
            }
            .en{
              color: @blue;
            }
          }
        }
        > a{
          font-size: 16px;
          align-items: center;
          justify-content: center;
          height: 100%;
          display: flex;
          color: #1a1a1a;
          .flex-direction;
          > .title{
            text-align: right;
            > span{
              margin-right: 4px;
              display: inline-block;
            }
            > .icon-arrow-down-bold{
              transition: all .3s;
            }
          }
          .en{

          }
        }
        & + li{
          margin-left: 38px;
        }
      }
    }
    > .cu-btn{
      margin-left: 36px;
      border-radius: 0;
    }
    > .user-link{
      margin-left: 36px;
      color: @blue;
      position: relative;
      .as-a;
      height: 82px;
        line-height: 82px;
      .title{
        max-width: 100px;
        font-size: 16px;
        display: inline-block;
      }
      &::after{
        content: '';
        display: inline-block;
        position: absolute;
        right: -20px;
        top: 50%;
        transform: translateY(-50%);
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 10.4px 6px 0 6px;
        border-color: currentColor transparent transparent transparent;
      }
      .user-sub-menu{
        background-color: #ffffff;
        box-shadow: 0px 2px 14px 0px
          rgba(0, 0, 0, 0.14);
        border-radius: 6px;
        font-size: 16px;
        line-height: 1.4;
        position: absolute;
        right: 0;
        top: 68px;
        display: none;
        width: max-content;
        > a {
          text-align: right;
          width: 100%;
          color: #4d4d4d;
          padding: 10px 22px;
          .flex;
          .flex-direction;
          .en{
            font-size: 14px;
          }
          &:hover{
            color: @blue;
          }
        }
      }
      &:hover{
        .user-sub-menu {
          display: inline-block;
        }
      }
    }
  }
  .lang-btn{
    display: flex;
    align-items: center;
    font-size: 16px;
    margin-left: 38px;
    color: #1a1a1a;
    .iconfont{
      margin-right: 8px;
      color: #1a1a1a;
    }
    a{
      color: #1a1a1a;
      &.active,&:hover{
        color: @blue;
      }
    }
  }
  &.index{
    background-color: transparent;
    background-image: url("@{image_url}header-bg.png");
    background-size: cover;
    background-repeat: no-repeat;
    .right-menu{
     .menu-list{
       >li{
         >a{
          color: #fff;
          .en {
            color: #fff;
          }
          > .iconfont{
            color: #fff;
          }
         }
        &:hover{
          > .sub-menu{
            display: block;
          }
          > a{
            color: @blue;
            > .title{
              > .icon-arrow-down-bold{
                transform: rotate(180deg);
              }
            }
            .en{
              color: @blue;
            }
          }
        }
        &.active{
          > a{
            color: @blue;
            > .title{
              > .icon-arrow-down-bold{
                transform: rotate(180deg);
              }
            }
            .en{
              color: @blue;
            }
          }
        }
       }
     }
    }

    .logo{
      .b-logo{
        display: none;
      }
      .w-logo{
        display: inline-block;
      }
    }
    .lang-btn{
      color: #fff;
      .iconfont{
        color: #fff;
      }
      a{
        color: #fff;
        &.active,&:hover{
          color: @blue;
        }
      }
    }
  }

}

.toTop{
  position: fixed;
  right: 2%;
  width: 100px;
  height: 100px;
  border: 1px solid @blue;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  bottom: 40px;
  border-radius: 50%;
  z-index: 103;
  cursor: pointer;
  background: @white;
  .iconfont{
    font-size: 16px;
  }
  .text{
    color: #333333;
    font-size: 18px;
    margin-top: 5px;
  }
}
// 弹窗
.popup{
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0,0.85);
  z-index: 1003;
  display: none;
}
.search-popup{
  z-index: 1004;
  display: none;
  .close{
      position: fixed;
      z-index: 1004;
      top: 35px;
      right: 35px;
      cursor: pointer;
      font-size: 42px;
      color: @white;
  }       
  .search-bar{
      position: fixed;
      left: 50%;
      top: 50%;
      margin-left: -600px;
      margin-top: -35px;
      width: 1200px;
      max-width: 100%;
      border: 1px solid @white;
      z-index: 89;
      height: 70px;
      form{
          display: flex;
          align-items: center;
          input{
              flex: 1;
              height: 70px;
              padding: 0 28px;
              font-size: 22px;
              color:  @white;
              background: transparent;
              &::placeholder,&::-moz-placeholder,&::-webkit-input-placeholder,&::-ms-input-placeholder{
                  color: @white !important;
              }
          }
          button{
              flex-shrink: 0;
              width: 80px;
              height: 70px;
              background: transparent;
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;
              .iconfont{
                  font-size: 38px;
                  color:  @white;
              }
          }
      }
  }
}
// 没结果
.no-result{
    text-align: center;
    padding: 106px 0 190px;
    .tips{
        font-size: 24px;
        color: #888;
        margin-top: 30px;
    }
}
// 搜索页
.page-search{
  .search-banner{
      background: #F5F5F5;
      padding-top: 230px;
      padding-bottom: 150px;
      .search-bar{
          width: 600px;
          height: 60px;
          background:@white;
          margin: 0 auto;
          form{
              display: flex;
              align-items: center;
              justify-content: center;
              margin-top: 24px;
              input{
                  flex: 1;
                  height: 60px;
                  font-size: 18px;
                  color: #333;
                  padding: 0 20px;
              }
              button{
                  width: 80px;
                  height: 60px;
                  cursor: pointer;
                  background: transparent;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  .iconfont{
                      font-size: 30px;
                      color: @blue;
                  }
              }
          }
      }
  }
  .list{
    padding: 60px 0;
    width: 1200px;
    margin: 0 auto;
    .total{
      font-size: 16px;
      color: #999;
      span{
          color: @blue;
          font-size: 16px;
      }
    }
    .item{
      padding:28px 0;
      position: relative;
      .title{
          font-size: 20px;
      }
      .date{
          color:#999;
          font-size: 16px;
          margin-top: 16px;
      }
      &::before{
          content: '';
          position: absolute;
          width: 100%;
          height: 1px;
          left: 0;
          bottom: 0;
          background:#F5F6F7;
      }
      &::after{
          content: '';
          position: absolute;
          width: 0;
          height: 1px;
          left: 0;
          bottom: 0;
          background: @blue;
          transition: all 1s;
      }
      &:hover{
          .title{
              color: @blue;
          }
          &::after{
              width: 100%;
          }
      }
    }
  }
}
footer{
  margin-top: 80px;
  position: relative;
  z-index: 102;
  .top-menu{
    background-color: #1d1d1d;
  }
  .copy-right-wrap{
    background-color: #171717;
    padding: 14px 0 ;
    font-size: 12px;
    color: #919191;
    .container{
      display: flex;
      justify-content: space-between;
      .copy-right-r{
        text-align: right;
      }
      .item{
        div{
          margin-bottom: 10px;
        }
        a{
          color: #919191;
        }
        div:last-child{
          margin-top: 4px;
          margin-bottom: 0;
        }
      }
    }
  }
  .container{
    .footer-menu{
      padding: 64px 0;
      // padding-bottom: 0;
    }
    .top-contact-msg{
      display: flex;
      .l-contact{
        flex: 0 0 652px;
        position: relative;
        color: #919191;
        .line-wrap{
          margin-top: 18px;
        }
        .line{
          margin-bottom: 10px;
          display: flex;
          font-size: 14px;
          line-height: 2;
          .name{
            flex-shrink: 0;
          }
        }
      }
      .partition{
        margin-left: 94px;
        margin-right: 94px;
        width: 1px;
        background-color: #292929;
      }
      .r-follow{
        .speech-wrap{
          font-size: 14px;
          line-height: 2.14;
          color: #919191;
          margin-top: 26px;
        }
        .qr-code-list{
          display: flex;
          margin-top: 26px;
          .item{
            position: relative;
            width: 42px;
            height: 42px;
            .as-a;
            > .icon-wrap{
              width: 100%;
              height: 100%;
              border-radius: 50%;
              background-color: rgba(255,255,255,0.11);
            }
            & + .item{
              margin-left: 20px;
            }
            .qr-code{
              width: 120px;
              height: 120px;
              padding: 10px;
              max-width: unset;
              max-height: unset;
              display: none;
              position: absolute;
              left: 100%;
              z-index: 2;
              top: 50%;
              background-color: #ffffff;
              box-shadow: 0px 14px 52px 0px
                #161616;
            }
            &:hover{
              .qr-code{
                display: inline-block;
              }
            }
          }
        }
      }
    }
    .friend-link-wrap{
      margin-top: 30px;
      .link-list{
        margin-top: 16px;
      }
      .link-list{
        display: flex;
        flex-wrap: wrap;
        margin-right: -34px;
        &-item {
          color: #cccccc;
          margin-right: 34px;
          margin-bottom: 30px;
        }
      }
    }
  }
}

//获取验证码
.get-verification {
  color: @blue;
  font-size: 14px;
}
.register-box {
  margin-top: 60px;
  .flex-center;
  .form {
    width: 550px;
    background-color: #ffffff;
    box-shadow: 0px 6px 20px 0px rgba(71, 6, 5, 0.08);
    > .title {
      font-size: 20px;
      text-align: center;
      margin-bottom: 22px;
      .login-way{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 22px;
        .line{
          height: 15px;
          width: 1px;
          background-color: #ccc;
          margin: 0 28px;
        }
        .item{
          font-size: 16px;
          color: #999;
          cursor: pointer;
          &.active{
            color: @blue;
          }
        }
      }
      h2 {
        margin-bottom: 6px;
        color: #1a1a1a;
      }
      > .en-text {
        font-size: 14px;
        color: @gray;
      }
    }
    .input-default{
      .get-verification {
        bottom: 15px;
        right: 12px;
      }
    }
    .btn-submit {
      margin-top: 20px;
      height: 40px;
      font-size: 14px;
    }
  }
}
.agree-checkbox{
  > label{
    > input{
      margin-right: 10px;
      margin-top: 4px;
    }
  }
}
.to-link-list{
  margin-bottom: 40px;
  justify-content: space-between;
  > a {
    font-size: 14px;
    > span{
      &:last-child{
        margin-top: 2px;
      }
    }
  }
}
.success-wrap{
  width: 660px;
  margin: 0 auto;
  padding: 110px 151px;
  .success-msg{
    text-align: center;
    margin-top: 12px;
    font-size: 20px;
    color: #1a1a1a;
    .en{
      margin-top: 4px;
      color: @gray;
      font-size: 14px;
    }
    .zh{
      text-align: center;
    }
  }
  .desc-wrap{
    line-height: 1.7;
    margin-top: 24px;
    .zh{
      font-size: 16px;
      color: #4d4d4d;
      margin-bottom: 14px;
    }
    .en{
      font-size: 16px;
      color: #4d4d4d;
    }
  }
  .btn-group{
    margin-top: 36px;
    width: 100%;
    text-align: center;
    .cu-btn{
      & + .cu-btn{
        margin-left: 62px;
      }
    }
  }
  &.sm-padding{
    padding: 40px 60px;
  }
  &.text-left{
    text-align: left;
  }
  &.flex-center{
    .flex;
    .flex-direction;
    align-items: center;
  }
}
.swiper-index-container{
  overflow: hidden;
  position: relative;
  .swiper-wrapper{
    height: 730px;
    width: 100vw;
    .swiper-slide{
      .content-text{
        position: absolute;
        top: 52%;
        left: 50%;
        transform: translate(-50%,-50%);
        p{
          width: 950px;
          line-height: 1.75;
          color: #fff;
          font-size: 24px;
          text-align: center;
        }
        .en{
          margin: 0 auto;
          width: 882px;
          margin-top: 40px;
        }
      }
      > .item{
        display: inline-block;
        position: relative;
        overflow: hidden;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 50%;
        transition: all 9s linear;
        > img{
          transition: all 9s;
        }
      }
    }
  }
}
.tourism-wrapper{
  margin-bottom: 30px;
  &:last-child{
    margin-bottom: 0;
  }
  .img-box{
    margin-right: 76px;
    width: 550px;
    img{
      width: 100%;
    }
  }
  .content{
    font-size: 14px;
    color: @light-black;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .title{
      margin-bottom: 26px;
      color: #1a1a1a;
      font-size: 24px;
    }
    .desc{
      line-height: 1.6;
    }
    .desc-en{
      line-height: 1.7;
    }
    .cu-btn{
      margin-top: 26px;
      margin-right: 20px;
    }
    .stop-reason{
      padding: 15px 16px;
      display: flex;
      align-items: flex-start;
      color: #fff;
      background: #f36109;
      height: auto;
      line-height: normal;
      min-height: 56px;
      margin-top: 26px;
      .iconfont{
        font-size: 20px;
        margin-right: 15px;
      }
      .text{
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
}
.tourism-list{
  .flex;
  .justify-between;
  .item{
    border: 1px solid @border;
    display: inline-flex;
    flex-direction: column;
    width: 550px;
    .img-box{
      width: 100%;
      height: 352px;
    }
    .content{
      padding: 26px 20px;
      font-size: 14px;
      color: @light-black;
      .title{
        margin-bottom: 10px;
        color: #1a1a1a;
        font-size: 24px;
      }
      .desc{
        margin-bottom: 20px;
      }
      .desc-en{
        line-height: 1.7;
      }
      .cu-btn{
        margin-top: 26px;
        margin-right: 20px;
        flex-shrink: 0;
      }
      .stop-reason{
        padding: 15px 16px;
        display: flex;
        align-items: flex-start;
        color: #fff;
        background: #f36109;
        height: auto;
        line-height: normal;
        min-height: 56px;
        margin-top: 26px;
        .iconfont{
          font-size: 20px;
          margin-right: 15px;
        }
        .text{
          font-size: 16px;
          line-height: 24px;
        }
      }
    }
    &:first-child{
      align-self: flex-start;
    }
    &:nth-child(2){
      margin-top: 56px;
    }
  }
}

.project-list{
  height: 560px;
  .flex;
  max-width: 100%;
  overflow: hidden;
  //background-image: url('https://www.dute.org/imgplaceholder/1920x560');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  > .item{
    flex: 1;
    border-right: 1px solid #fff;
    > .content{
      margin-top: 250px;
      padding-left: 30px;
      padding-right: 30px;
      .direction{
        font-size: 14px;
        padding: 0 22px;
        height: 48px;
      }
    }
    .title-wrap{
      color: #fff;
      margin-bottom: 28px;
      .title{
        font-size: 24px;
        margin-bottom: 10px;
      }
      .title-en{
        font-size: 16px;
      }
    }
    &:hover{
      background: rgba(17,124,176,0.9);
    }
    &:last-child{
      border-right: none;
    }
  }
}

.latest-news-list{
  display: flex;
  flex-wrap: wrap;
  .item{
    width: 367px;
    .img-box{
      width: 100%;
      height: 234px;
      margin-bottom: 24px;
    }
    .content{
      font-size: 16px;
      color: #1a1a1a;
      line-height: 1.75;
      .title{
        margin-bottom: 16px;
        word-break: break-all;
        font-size: 20px;
      }
      .title-en{
        padding-bottom: 22px;
      }
      .date{
        border-top: 1px solid @border;
        padding-top: 18px;
        font-weight: bold;
        font-size: 24px;
        color: @gray;
      }
    }
    &:not(:nth-child(3n)) {
      margin-right: calc(79px / 2);
    }
  }
}
.index-partner-list{
  display: flex;
  flex-wrap: wrap;
  .item{
    padding: 10px;
    width: 170px;
    height: 80px;
    background-color: #ffffff;
    margin-bottom: 40px;
    border: solid 1px #ededed;
    > img{
      max-width: 100%;
      height: auto;
    }
    &:not(:nth-child(6n)) {
      margin-right: calc(160px / 5);
    }
  }
}
.banner-modules-box{
  position: relative;
  margin-bottom: 70px;
  // .img-wrapper(100%,320px);
  img{
    display: block;
    width: 100%;
    // height: 100%;
  }
  .banner-text-wrap{
    position: absolute;
    color: #fff;
    font-size: 16px;
    text-align: center;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    .flex;
    .flex-direction;
    .zh{
      font-size: 36px;
      margin-bottom: 6px;
    }
  }
}
.o-sidebar{
  > .item{
    background-color: #f5f5f5;
    color: #4c4c4c;
    display: flex;
    align-items: center;
    padding: 12px 20px;
    margin-bottom: 10px;
    width: 250px;
    .title{
      font-size: 16px;
      position: relative;
      &.badge{
        &::after{
          position: absolute;
          content: '';
          background-color: @red;
          width: 8px;
          height: 8px;
          display: inline-block;
          border-radius: 50%;
          right: -12px;
          top: 0;
        }
      }
    }
    .title-en{
      margin-top: 4px;
      display: block;
      font-size: 14px;
    }
    &:hover,&.active{
      color: #fff;
      background-color: @blue;
    }
  }
}
.community-based-list{
  > .item{
    padding-bottom: 40px;
    .img-box{
      width: 300px;
      height: 192px;
      margin-right: 29px;
    }
    .content{
      position: relative;
      font-size: 16px;
      color: #1a1a1a;
      .title{
        line-height: 1.75;
        font-size: 18px;
        margin-bottom: 10px;
        word-break: break-all;
      }
      .title-en{
        line-height: 1.75;
        margin-bottom: 22px;
      }
      .date{
        font-size: 14px;
        position: absolute;
        bottom: 0;
        left: 0;
        color: @gray;
        padding-top: 20px;
        border-top: 1px solid @border;
        width: 100%;
      }
    }
  }
}
.detail-wrap{
  .detail-wrap-title{
    font-size: 36px;
    color: #1a1a1a;
    .title{
      margin-bottom: 6px;
    }
    .title-en{
      font-size: 24px;
    }
  }
  .detail-top-wrap{
    padding: 10px 0;
    border-bottom: 1px solid @border;
    .date{
      .flex;
      .align-center;
      font-size: 14px;
      color: @gray;
      margin-right: 176px;
    }
    .cu-btn{
      height: 40px;
    }
  }
  .article-content{
    margin-top: 40px;
  }
}
.detail-wrap{
  margin-top: 40px;
  .detail-top-wrap{
    .date{
      > span{
        margin-left: 10px;
      }
    }
    .cu-btn{
      font-size: 14px;
    }
  }
}
// 侧栏目录
.sidebar-list{
  padding: 20px 15px;
  width: 300px;
	background-color: #f5f5f5;
	border: solid 1px #ededed;
  margin-bottom: 34px;
  ul{
    li{
      margin-bottom: 20px;
      a{
        display: block;
        font-size: 16px;
        color: #4d4d4d;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        text-decoration: underline;
        &:hover{
          color: @blue;
        }
      }
      &:last-child{
        margin-bottom: 0;
      }
    }
  }
}
.detail-right-sidebar{
  margin-top: 20px;
  .right-sidebar-list{
    .item{
      display: flex;
      flex-direction: column;
      box-shadow: none;
      border-bottom: 1px solid @border;
      margin-bottom: 30px;
      .content{
        padding: 0;
        max-width: 300px;
        .title{
          font-size: 18px;
          color: #1a1a1a;
          margin: 16px 0;
          word-break: break-all;
        }
        .cu-btn{
          margin-bottom: 30px;
        }
      }
    }
  }
  .mulu-list{
    display: flex;
    flex-wrap: wrap;
    margin-top: 30px;
    width: 320px;
    > .item{
      width: 145px;
      overflow: hidden;
      position: relative;
      margin-bottom: 10px;
      margin-right: 10px;
      .text{
        padding: 0 10px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        color: @white;
        font-size: 14px;
        background: rgba(0, 0, 0,0.4);
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
  }
}
.question-list{
  li {
    margin-bottom: 30px;
    padding-bottom: 30px;
    border-bottom: solid 1px #ededed;
    .item {
      > .iconfont{
        flex-shrink: 0;
      }
      .icon-question{
        flex: 0 0 20px;
      }
      font-size: 18px;
      font-weight: bold;
      cursor: pointer;
      color: @black;
      .flex;
      .align-center;
      justify-content: space-between;
      .title{
        margin-left: 10px;
        margin-right: 10px;
      }
    }
    .item-content{
      width: 100%;
      padding: 20px;
      padding-bottom: 0;
      position: relative;
      position: relative;
      > .article-content{
        padding-top: 0;
      }
    }
  }
}
.service-appointment-list{
  > .item {
    margin: 40px 0;
    .img-box{
      width: 300px;
      height: 192px;
      margin-right: 29px;
    }
    .content{
      min-height: 100%;
      border-bottom: 1px solid @border;
      font-size: 14px;
      position: relative;
      color: #1a1a1a;
      .l-w{
        width: 610px;
        max-width: 610px;
      }
      .title{
        font-size: 18px;
        padding-top: 8px;
        margin-bottom: 14px;
      }
      .desc{
        color: @light-black;
        margin-bottom: 14px;
        line-height: 1.7;
      }
      .desc-en{
        color: @light-black;
        line-height: 1.7;
      }
      .cu-btn{
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }
    .stop-reason{
      height: auto;
      padding: 10px 16px;
      line-height: 30px;
      margin-bottom: 20px;
    }
  }
}
.project-detail-wrap{
  margin-top: 40px;
  margin-bottom: 30px;
  .img-box{
    width: 570px;
    margin-right: 60px;
    img{
      height: 100%;
    }
  }
  .content{
    .title{
      color: #1a1a1a;
      font-size: 28px;
      padding-bottom: 28px;
      border-bottom: 1px solid @border;
      margin-bottom: 24px;
    }
    .appointment_panel{
      height: calc(100% - 97px);
      display: flex;
      flex-direction: column;
      .stop-reason{
        margin-top: auto;
      }
    }
    .appointment_panel > .line{
      display: flex;
      align-items: center;
      margin-bottom: 28px;
      font-size: 14px;
      .name{
        color: #808080;
        flex-shrink: 0;
        width: 134px;
        margin-right: 4px;
      }
      .toggle-language{
        height: 32px;
      }
      &:last-child{
        margin-bottom: 0;
      }
    }
    .date-wrap{

    }
    .appointment_panel .accommodation-wrap{
      align-items: center;
      .accommodation-wrap-list{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        > .cu-btn{
          flex-direction: column;
          align-items: flex-start;
          height: 46px;
          font-size: 14px;
          width: 180px;
          padding: 0 12px;
          margin-top: 28px ;
          color: #4d4d4d;
          > span {
            &:last-child{
              margin-top: 4px;
            }
          }
          &:hover,&.active{
            color: @blue;
          }
          &:nth-child(1),&:nth-child(2){
            margin-top: 0;
          }
        }
      }
    }
    .total-wrap{
      .name{
        width: 90px;
      }
      .total{
        margin-right: 50px;
        font-size: 18px;
        color: #000000;
        > span{
          font-size: 30px;
        }
      }
    }
  }
}
.sai-container{
  .ant-input-number-handler-wrap{
    opacity: 1;
  }
  .ant-input-number-handler{
    .flex-center;
    > .ant-input-number-handler-down-inner,> .ant-input-number-handler-up-inner{
      transform: none;
      top: unset;
    }
  }
  .ant-input-number-handler-down-inner,.ant-input-number-handler-up-inner{
    .flex-center;
    line-height: unset;
  }
  .ant-input-number-handler-up-inner{
    transform: translateY(-50%);
    margin-top: 0;
  }
}
.eval-record-list{
  .item {
    border-bottom: 1px solid @border;
    .flex;
    justify-content: space-between;
    padding-top: 30px;
    .avatar-box{
      width: 50px;
      flex-shrink: 0;
      height: 50px;
      border-radius: 50%;
      margin-right: 19px;
      overflow: hidden;
    }
    .content{
      font-size: 14px;
        color: #4d4d4d;
      .name-wrap{
        margin-bottom: 12px;
        .flex;
        align-items: flex-end;
        .name{
          font-size: 16px;
          color: #1a1a1a;
        }
        .date{
          font-size: 14px;
          color: @gray;
          margin-left: 16px;
        }
      }
      .desc,.desc-en{
        line-height: 1.7;
      }
      .desc{
        margin-bottom: 6px;
      }
      .desc-en{
        margin-bottom: 18px;
      }
      .img-list {
        .flex;
        flex-wrap: wrap;
        width: 750px;
        > .img-item{
          margin-bottom: 30px;
          .flex;
          flex-wrap: wrap;
          width: 226px;
          height: 144px;
          overflow: hidden;
          align-items: center;
          justify-content: center;
          &:not(:nth-child(3n)) {
            margin-right: calc(48px / 2);
          }
        }
      }
    }
    &:first-child{
      padding-top: 0px;
    }
  }
}
.tab-top-menu{
  border-bottom: 1px solid @border;
  margin-bottom: 40px;
  .flex;
  .tab-menu{
    margin-right: 100px;
    .title{
      .flex;
      .flex-direction;
      font-size: 20px;
      color: #1a1a1a;
      position: relative;
      > span:first-child{
        margin-bottom: 10px;
      }
      > span:last-child{
        font-size: 16px;
        color: @gray;
        padding-bottom: 10px;
      }
      &::before{
        content: '';
        transition: all @transition;
        display: inline-block;
        position: absolute;
        left: 0;
        bottom: 0;
        height: 3px;
        width: 0%;
        background-color: @blue;
      }
      &:hover,&.active{
        &::before{
          width: 100%;
        }
      }
    }
    &:last-child{
      margin-right: 0;
    }
  }
  &.en{
    .tab-menu{
      margin-right: 68px;
      &:last-child{
        margin-right: 0;
      }
    }
  }
}
.gird-830{
  > .l{
    width: 830px;
  }
  .fixed{
    position: fixed;
    z-index: 99;
    width: 300px;
    top: 116px;
  }
}
.sidebar-city{
  margin-bottom: 24px;
  width: 300px;
  height: 192px;
  border: solid 1px #ededed;
}
.sidebar-contact-wrap{
  padding: 20px 15px;
  width: 300px;
	background-color: #f5f5f5;
	border: solid 1px #ededed;
  .line{
    margin-bottom: 16px;
    .name{
      flex-shrink: 0;
      margin-right: 10px;
    }
    .text{
      font-size: 14px;
      color: #4d4d4d;
      line-height: 1.7;
    }
    &:last-child{
      margin-bottom: 0;
    }
  }
}
.order-confirm-wrap{
  padding-top: 36px;
  .line-bar{
    height: 1px;
    width: 100%;
    background-color: @border;
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .base-wrap{
    margin-bottom: 30px;
    > .name{
      margin-bottom: 16px;
      font-size: 16px;
      color: #1a1a1a;
    }
  }
  .date-wrap{
    position: relative;
    .toggle-language{
      height: 36px;
      // position: absolute;
      // top: 0;
      // left: 200px;
    }
    .ant-picker{
      width: 223px;
      height: 36px;
    }
    .date-b{
      font-size: 14px;
      color: @gray;
      > .tips-text{
        margin-left: 20px;
      }
    }
  }
  .person-number-border{
    width: 73px;
    padding: 0 12px;
    height: 36px;
    line-height: 36px;
    border: solid 1px #dbdbdb;
  }
  .accommodation-wrap{
    &-list{
      .cu-btn{
        width: 180px;
        height: 46px;
        padding: 0 12px;
        font-size: 14px;
        flex-direction: column;
        border: solid 1px #dbdbdb;
        align-items: flex-start;
        &.active,&:hover{
          color: @blue;
        }
        & + .cu-btn{
          margin-left: 20px;
        }
      }
    }
  }
  .person-msg-wrap{
    > .tips-text{
      font-size: 16px;
      color: #4d4d4d;
      margin-bottom: 26px;
      .zh{
        margin-bottom: 10px;
      }
    }
    .person-msg{
      margin-bottom: 30px;
      .order-title-wrap{
        .title{
          font-size: 16px;
          color: #1a1a1a;
        }
        .add-person{
          font-size: 16px;
          .as-a;
          .bg-blue;
          color: #fff;
          cursor: pointer;
          .bg-blue;
          margin-top: 15px;
          display: inline-block;
          padding: 5px 10px;
        }
      }
      .person-msg-item{
        margin-top: 18px;
        .flex;
        align-items: center;
        .msg-wrap{
          width: 580px;
          height: 36px;
          background-color: #f5f5f5;
          border: solid 1px #dbdbdb;
          padding: 0 12px;
          font-size: 14px;
          color: #808080;
          display: flex;
          align-items: center;
          .name{
            color: #4d4d4d;
          }
          .age,.age-en{
            margin-left: 23px;
          }
        }
        .edit{
          margin-left: 31px;
        }
        .delete{
          margin-left: 34px;
        }
      }
    }
  }
  .order-config-form-wrap{
    .ant-form-item{
      .ant-form-item-label{
        padding-bottom: 14px;
        > label{
          font-size: 16px;
          color: #1a1a1a;
        }
      }
      .ant-input{
        height: 40px;
      }
    }
    .ant-checkbox:hover::after, .ant-checkbox-wrapper:hover .ant-checkbox::after{
      visibility: hidden;
    }
  }
  .pay-way-list{
    .flex;
    .item{
      .as-a;
      width: 260px;
      height: 72px;
      background-color: #fff1e7;
      border: 1px solid transparent;
      .flex;
      .align-center;
      padding: 0 14px;
      .desc{
        margin-left: 15px;
        .flex;
        .align-center;
        .zh{
          font-size: 16px;
          color: #1a1a1a;
        }
        .en{
          margin-left: 10px;
          font-size: 14px;
          color: @gray;
        }
      }
      &.active{
        border-color: @blue;
      }
      &:not(:nth-child(3n)) {
        margin-right: 30px;
      }
    }
  }
  .pay-tips{
    font-size: 16px;
    margin-top: 20px;
    color: @blue;
    display: none;
    .zh{
      margin-bottom: 4px;
    }
    &.active{
      display: block;
    }
  }
  .agree-wrap{
    .ant-checkbox-wrapper{
      display: flex;
      .ant-checkbox{
        > input{
          width: 18px;
          height: 18px;
          margin-top: 4px;
        }
      }
      .agree-wrap-r{
        color: @light-black;
        font-size: 14px;
        margin-left: 10px;
        > .zh{
          margin-bottom: 6px;
        }
      }
    }
  }
  #appointment{
    margin-top: 44px;
  }
}
.qs-form-popup{
  .ant-form-item{
    margin-bottom: 14px;
    .ant-form-item-label{
      padding-bottom: 12px;
      > label{
        color: #1a1a1a;
      }
    }
    .ant-picker{
      width: 100%;
    }
    .ant-form-item-control{
      .ant-input,.ant-select-selector,.ant-picker{
        height: 44px;
        .ant-select-selection-search-input{
          height: 44px;
        }
        .ant-select-selection-item,.ant-select-selection-placeholder{
          line-height: 44px;
        }
      }
    }
    &.has-extra{
      .ant-form-item-label{
        padding-bottom: 0;
      }
      .ant-form-item-extra{
        margin-bottom: 10px;
      }
    }
  }
  .btn-group{
    margin-top: 40px;
    justify-content: space-between;
    .cu-btn{
      width: 180px;
      height: 44px;
      font-size: 14px;
      &.bg-blue{
        background-color: @blue;
        color: #fff;
      }
    }
  }
}
.cost-detail-wrap{
  background-color: #f5f5f5;
  border: solid 1px #ededed;
  padding: 20px 16px;
  position: relative;
  .cost-instructions{
    // max-height: 25px;
    overflow-y: hidden;
    .cost-content{
      border: solid 1px #ededed;
      padding: 20px 16px;
      max-height: 350px;
      overflow-y: auto;
    }
  }
  .show-more-button{
    .as-a;
    position: absolute;
    right: 0;
    color: @blue;
    top: -39px;
  }
  .group{
    margin-bottom: 18px;
    .title{
      font-size: 14px;
      color: #4d4d4d;
      margin-bottom: 8px;
    }
    .detail-msg{
      display: flex;
      font-size: 14px;
      color: @gray;
      .multiply{
        margin-left: 16px;
        > span{
          margin-bottom: 4px;
          &:last-child{
            margin-bottom: 0;
          }
        }
      }
      .money{
        align-self: flex-end;
        flex: 1;
        display: flex;
        justify-content: flex-end;
      }
    }
  }
  .total-wrap{
    padding-top: 14px;
    border-top: 1px solid @border;
    font-size: 14px;
    color: @gray;
    display: flex;
    justify-content: flex-end;
    > .total{
      color: #ef7d0b;
      margin-left: 8px;
      font-size: 16px;
    }
  }
  &.no-padding-border{
    padding: 0;
    border: none;
  }
}
.pay-wrap{
  padding: 56px 60px;
  .flex;
  flex-direction: column;
  align-items: center;
  width: 660px;
  margin: 0 auto;
  margin-top: 60px;
  .img-box{
    padding: 13px;
    width: 218px;
    height: 218px;
    border: 1px solid @border;
    background-color: #fff;
    margin-bottom: 24px;
    > img{
      width: 100%;
      height: 100%;
    }
  }
  .pay-way{
    display: flex;
    font-size: 16px;
    color: #1a1a1a;
    > img{
      width: 40px;
      height: 39px;
      margin-right: 16px;
    }
    .pay-way-text{
      .en{
        margin-top: 6px;
        font-size: 14px;
        color: @gray;
      }
    }
  }
  .tips-text{
    margin-top: 50px;
    font-size: 14px;
    line-height: 1.7;
    .text-title{
      margin-bottom: 10px;
    }
  }
}
.user-sidebar-wrap{
  color: #fff;
  .avatar-wrap{
    padding: 26px 20px;
    background-color: @blue;
    .flex;
    margin-bottom: 10px;
    .avatar-box{
      width: 58px;
      height: 58px;
      border: solid 2px #ffffff;
      overflow: hidden;
      border-radius: 50%;
      margin-right: 8px;
      > img{
        width: 100%;
        height: 100%;
      }
    }
    .r-email{
      font-size: 16px;
      .email{
        margin-bottom: 10px;
        max-width: 140px;
      }
    }
  }
}
.my-order-list{
  > .item{
    margin-top: 40px;
    margin-bottom: 40px;
    height: 192px;
    .img-box{
      width: 300px;
      margin-right: 30px;
    }
    .content{
      max-width: 550px;
      border-bottom: 1px solid @border;
      height: 100%;
      position: relative;
      .l-r-wrap{
        .l{
          max-width: 68%;
        }
      }
      .btn-group{
        position: absolute;
        right: 0;
        bottom: 30px;
        .flex;
        .flex-direction;
        .cu-btn{
          & + .cu-btn{
            margin-top: 19px;
          }
          &.badge{
            position: relative;
           &:before{
             content:'';
             display: inline-block;
             position: absolute;
             width: 8px;
             height: 8px;
             background-color: #ff0000;
             border-radius: 50%;
             right: 7px;
             top: 10px;
           }
          }
        }
      }
      .title{
        font-size: 18px;
        color: #1a1a1a;
      }
      .msg-wrap{
        padding-top: 14px;
        padding-bottom: 18px;
        .line{
          margin-bottom: 12px;
          font-size: 14px;
          color: @gray;
          .flex;
          align-items: center;
          > .icon{
            flex-shrink: 0;
          }
          .text{
            margin-left: 12px;
          }
          &:last-child{
            margin-bottom: 0;
          }
        }
      }
      .text-orange{
        font-size: 14px;
        color: #ef7d0b;
      }
    }
    &:first-child{
      margin-top: 30px;
    }
  }
}
.my-order-not-data{
  margin-top: 126px;
  margin-bottom: 126px;
  .desc-wrap{
    font-size: 18px;
    color: #4d4d4d;
    .en{
      margin-top: 12px;
    }
    margin-bottom: 60px;
  }
}
.order-detail-wrap{
  .btn-group{
    margin-top: 40px;
    text-align: right;
  }
  .group{
    font-size: 14px;
    margin-top: 34px;
    border-bottom: 1px solid @border;
    .cost-detail-wrap{
      margin-bottom: 16px;
      padding: 16px 12px;
      .cost-line{
        width: 100%;
        .flex;
        .justify-between;
        background-color: #f5f5f5;
        color: #4c4c4c;
        margin-bottom: 20px;
        .r{
          color: #808080;
          .flex;
          .align-center;
          .amount{
            margin-left: 50px;
          }
        }
        &:last-child{
          margin-bottom: 0;
        }
      }
    }
    .cost-total{
      text-align: right;
      color: #808080;
      padding-bottom: 34px;
    }
    .reason-text{
      color: #808080;
      line-height: 1.7;
    }
    .person-msg-wrap{
      .flex;
      .justify-between;
      font-size: 14px;
      margin-bottom: 20px;
      .l{
        color: #4d4d4d;
      }
      .msg{
        color: #808080;
      }
      &:last-child{
        margin-bottom: 40px;
      }
    }
    > .line{
      padding-bottom: 34px;
      .flex;
      .justify-between;
      &.sm-padding-bottom{
        padding-bottom: 22px;
      }
      &.s-padding-bottom{
        padding-bottom: 14px;
      }
      > .name{
        font-size: 16px;
        color: #1a1a1a;
      }
      > .text{
        font-size: 14px;
        color: @gray;
      }
    }
  }
}
.qs-s-form-popup{
  color: #4d4d4d;
  font-size: 14px;
  .form-title{
    color: #1a1a1a;
    font-size: 16px;
    margin-bottom: 24px;
  }
  .checkbox-list{
    .checkbox-item{
      margin-bottom: 20px;
      label{
        .flex;
        align-items: center;
        > input[type="radio"] {
          width: 15px;
          height: 15px;
          border-radius: 0;
        }
        .text{
          margin-left: 12px;
        }
      }
    }
  }
  .reason-text{
    margin-bottom: 26px;
    > input{
      width: 382px;
      height: 44px;
      padding: 0 20px;
      border: solid 1px #dbdbdb;
      outline: none;
      margin-left: 31px;
    }
  }
  .tips-text{
    line-height: 1.7;
    margin-bottom: 30px;
    .zh{
      margin-bottom: 6px;
    }
  }
  .input-group{
    margin-bottom: 26px;
    overflow: hidden;
    .field{
      color: #1a1a1a;
      font-size: 16px;
      padding-bottom: 18px;
      > span{
        margin-left: 20px;
      }
    }
    > textarea{
      width: 100%;
      padding: 10px;
      height: 108px;
	    border: solid 1px #dbdbdb;
    }
    .ossuploader-upload-box{
      margin-right: -21px;
    }
    .ossuploader-add, .ossuploader-dash-border{
      width: 120px;
      height: 76px;
      margin-right: 21px;
      margin-bottom: 21px;
    }
    .ossuploader-add i{
      width: 20px;
      height: 20px;
    }
    .ossuploader-add > p {
      position: relative;
      font-size: 12px;
      width: 80%;
      &::after{
        content: 'Upload';
        position: absolute;
        right: 0;
        top: 0;
      }
    }
    &.has-bottom-border{
      padding-bottom: 20px;
      border-bottom: 1px solid @border;
    }
  }

}
.validate-img{
  > img{
    max-height: 44px;
  }
}
#add-person-popup{
  width: 800px;
  .popup-content{
    overflow-x: hidden;
  }
  .explain{
    font-size: 14px;
    line-height: 26px;
    color: #117cb0;
    margin-bottom: 14px;
  }
  .id-card-wrap{
    position: relative;
    margin-bottom: 14px;
    > label{
      position: relative;
      &::before{
        display: inline-block;
        margin-right: 4px;
        color: #ff4d4f;
        font-size: 14px;
        font-family: SimSun, sans-serif;
        line-height: 1;
        content: '*';
      }
    }
    .ossuploader-add, .ossuploader-dash-border{
      width: 130px;
      height: 76px;
      margin-right: 12px;
    }
    .ossuploader-add i{
      width: 20px;
      height: 20px;
    }
    .ossuploader-add > p {
      position: relative;
      margin-bottom: 0;
      font-size: 12px;
      width: 80%;
      &::after{
        content: 'Upload';
        position: absolute;
        right: 0;
        top: 0;
      }
    }
    .ossuploader-dash-border:last-child{
      margin-right: 0;
    }
    .input-error-msg{
      display: none;
      min-height: 24px;
      position: absolute;
      left: 0;
      bottom: -18px;
      color: #ff4d4f;
    }
    &.error{
      .input-error-msg{
        display: block;
      }
    }
  }
}
.gird-830 {
  > .order-sidebar-r{
    transition: all @transition;
    height: auto;
    &.toTop{
      top: calc(~"100vh - 330px");
    }
  }
}
.sm-font{
  font-size: 14px;
  color: @gray;
}
.footer-popup-wrap{
  .btn-group{
    margin-top: 40px;
    .cu-btn{
      cursor: pointer;
    }
    .time{
      display: inline-block;
      margin-left: 10px;
    }
  }
}

// 预约目录文章详情
.chapter{
  margin-bottom: 10px;
  .title{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #F5F5F5;
    border: 1px solid #EDEDED;
    height: 38px;
    line-height: 38px;
    padding: 0 10px;
    margin-bottom: 20px;
    margin-top: 30px;
    span{
      font-size: 18px;
      color: #1A1A1A;
    }
    .iconfont{
      font-size: 16px;
      color: #4D4D4D;
      transition: all .3s;
      display: inline-block;
    }
  }
  >.article-content{
    padding-left: 10px;
  }
  &.active{
    .title{
      .iconfont{
        transform: rotate(180deg);
      }
    }
  }
}
.chapter-map{
  height: 300px;
  width: 100%;
  margin: 40px 0;
}
.stop-reason{
  padding: 10px 16px;
  display: inline-block;
  color: @white;
  background: #f36109;
  .iconfont{
    font-size: 16px;
    margin-right: 10px;
  }
  .text{
    font-size: 16px;
  }
}

.article-list{
  .item{
    margin-top: 30px;
    .title{
      text-align: left;
      font-size: 24px;
      font-weight: bold;
      border-bottom: 2px solid rgb(204, 204, 204);
      color: rgb(89, 89, 89);
      line-height: 36px;
    }
    .article-content{
      margin-top: 10px;
    }
  }
}

// 侧栏目录
.appointment-sidebar-list{
  padding: 20px 15px;
  width: 300px;
	background-color: #f5f5f5;
	border: solid 1px #ededed;
  margin-bottom: 34px;
  ul{
    li{
      margin-bottom: 20px;
      a{
        display: block;
        font-size: 16px;
        color: #4d4d4d;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        &:hover{
          color: @blue;
          text-decoration: underline;
        }
      }
      &:last-child{
        margin-bottom: 0;
      }
    }
  }
}
.h-module-title2{
  width: 100%;
  height: 36px;
  line-height: 36px;
  text-align: center;
  background: @blue;
  font-size: 18px;
  color: @white;
}
.order-section{
  box-shadow: 0px 1px 7px 0px rgba(26,28,36,0.14);
  .cont{
    padding: 30px 20px 1px 20px;
  }
  margin-bottom: 40px;
  &.userSection1{
    position: relative;
    margin-bottom: 0;
  }
  &.userSection2{
    position: relative;
    transform: translateY(-10px);
    z-index: 2;
    background: #fff;
    .cont{
      padding-top: 0;
      padding-bottom: 30px;
    }
  }
}
.whiteline{
  height: 10px;
  background: #fff;
  position: relative;
  transform: translateY(-10px);
  z-index: 3;
}
.userEdit{
  .verifyitem{
    input{
      width: 520px;
    }
  }
  .msgitem{
    input{
      width: 520px;
    }
    .get-verification{
      position: absolute;
      bottom: 12px !important;
      right: 12px !important;
    }
  }
}
.btnGroup{
  display: flex;
  align-items: center;
  margin-top: 28px;
  margin-bottom: 28px;
  .cu-btn{
    margin-right: 20px;
    height: 48px;
    line-height: 48px;
    text-align: center;
    border: 1px solid #117CB0;
    font-size: 16px;
    min-width: 140px;
    &:nth-child(2){
      color: #117CB0;
    }
  }
}
.download_list{
  li{
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    font-size: 16px;
    color: #1A1A1A;
    a{
      color: #1A1A1A;
    }
    .download_btn{
      margin-left: 20px;
      color: @blue;
    }
  }
}
.download_tips{
  font-size: 16px;
  color: @blue;
  margin-bottom: 24px;
}

.problem-list{
  .item{
    padding: 30px 0;
    border-bottom: 1px solid #EDEDED;
    &:nth-child(1){
      padding-top: 0;
    }
    .title{
      display: flex;
      align-items: center;
      justify-content: space-between;
      span{
        width: 80%;
        font-weight: bold;
        font-size: 18px;
      }
      .iconfont{
        font-size: 16px;
        color: #666666;
        transition: all .3s;
        display: inline-block;
      }
      &.active{
        .iconfont{
          transform: rotate(180deg);
          color: #117CB0;
        }
      }
    }
    .article-content{
      padding: 0 18pxx;
      margin-top: 20px;
      display: none;
    }
  }
}
.ant-radio-wrapper>span:nth-child(2){
  white-space:break-spaces;
}