@import "./var";

.text-pre-wrap{
  white-space: pre-wrap;
}
.text-right{
	text-align: right;
}
.text-center{
	text-align: center;
}
.text-cut {
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}
.text-cut-2 {
	overflow : hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
}
.text-cut-3 {
	overflow : hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
}

.flex {
	display: flex;
}
.flex-shrink{
	flex-shrink: 0;
}
.basis-xs {
	flex-basis: 20%;
}

.basis-sm {
	flex-basis: 40%;
}

.basis-df {
	flex-basis: 50%;
}

.basis-lg {
	flex-basis: 60%;
}

.basis-xl {
	flex-basis: 80%;
}

.flex-sub {
	flex: 1;
}

.flex-twice {
	flex: 2;
}

.flex-treble {
	flex: 3;
}

.flex-direction {
	flex-direction: column;
}

.flex-wrap {
	flex-wrap: wrap;
}

.align-start {
	align-items: flex-start;
}

.align-end {
	align-items: flex-end;
}

.align-center {
	align-items: center;
}

.align-stretch {
	align-items: stretch;
}

.self-start {
	align-self: flex-start;
}

.self-center {
	align-self: flex-center;
}

.self-end {
	align-self: flex-end;
}

.self-stretch {
	align-self: stretch;
}

.align-stretch {
	align-items: stretch;
}

.justify-start {
	justify-content: flex-start;
}

.justify-end {
	justify-content: flex-end;
}

.justify-center {
	justify-content: center;
}

.justify-between {
	justify-content: space-between;
}

.justify-around {
	justify-content: space-around;
}
.flex-center{
  .flex;
  .align-center;
  .justify-center;
}


.l-img-r-content{
  .flex;
  .justify-between;
  > .img,>.img-box{
    flex-shrink: 0;
  }
  .content{
    flex: 1;
  }
}
.hidden {
    display: none !important;
}

.bg-blue{
	color: #ffffff;
	background-color: @blue;
}
.bg-gray {
	color: #4d4d4d;
    background-color: #ededed;
}
.bg-disable {
	color: #fff;
	background-color: #cccccc;
}
.bg-white {
	background-color: #ffffff;
}


.en{
	word-break: break-word;
}
.text-blue{
	color: @blue;
}
.text-white{
	color: #ffffff;
}
.text-dark-blue{
	color: @darkBlue;
}
.text-gray{
	color: @gray;
}
.text-light-black{
	color: @light-black;
}

  .tab-pane{
	display: none;
	&.active{
	  display: block;
	}
  }

.popup-wrapper,.msgBox{
	.msgBoxContent,.article-content{
		p,div,span{
			word-break: break-word;
			text-align: justify;
			width: 100%;
		}
	}
}