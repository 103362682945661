
@keyframes slideIn {
    0% {max-height: 1000px;}
    100% {max-height: 25px;}
}

@keyframes slideOut {
    0% {max-height: 25px;}
    100% {max-height: 1000px;}
}
.slide-animation{
    &-enter{
      max-height: 0px;
    }
    &-enter-active{
      max-height: 1000px;
      transition: max-height .4s;
    }
    &-enter-done{
      max-height: 1000px;
    }
    &-exit{
      max-height: 1000px;
    }
    &-exit-active{
      max-height: 0px;
      transition: max-height .4s;
    }
    &-exit-done{
      max-height: 0px;
    }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}
.fade {
  &-enter {
    animation-name: fadeIn;
    animation-duration: 2000ms;
  }
  &-exit {
    animation-name: fadeOut;
    animation-duration: 2000ms;
  }
}