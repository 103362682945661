@import "./var";
@import "../../../commonStyle/common.less";

.container{
  width: 1180px;
  margin: 0 auto;
}
.as-a{
  cursor: pointer;
}
.page-article-detail{
  .article-title{
    font-size: 28px;
    margin-bottom: 40px;
    color: @black;
    text-align: center;
  }
}
.setBg(@url,@w: 100%,@h: 100%,@position: left top) {
  background-image: url("@{image_url}@{url}");
  background-position: @position;
  background-size: 100% 100%;
  width: @w;
  height: @h;
  background-repeat: no-repeat;
}
.gird-l-r{
  .flex;
  .justify-between;
  > .l{
    flex-shrink: 0;
  }
  > .r{
    flex: 1;
    height: 100%;
  }
}
.grid-8-3{
  .flex;
  .justify-between;
  .col-8 {
    flex: 1;
    height: 100%;
    margin-right: 40px;
    background-color: #ffffff;
    box-shadow: 0px 2px 12px 0px 
      rgba(9, 9, 9, 0.12);
      &.mr0{
        margin-right: 0;
      }
  }
  .col-3{
    height: 100%;
    flex-shrink: 0;
    width: 328px;
    background-color: #ffffff;
	  box-shadow: 0px 2px 12px 0px 
		rgba(9, 9, 9, 0.12);
  }
}

.list-item-hover{
  box-shadow: 0px 2px 12px 0px 
  rgba(9, 9, 9, 0.12);
    .content{
      border-bottom: none;
      .title{
        color: @green;
      }
    }
}

.base-v-list {
  .flex;
  .flex-wrap;
  > .item {
    background-color: #ffffff;
    box-shadow: 0px 2px 12px 0px 
      rgba(9, 9, 9, 0.12); 
    > .img,>.img-box {
      width: 100%;
      display: block;
    }
    .content{
      width: 100%;
      font-size: 18px;
      color: #1a1a1a;
      padding: 23px  6px;
      text-align: left;
    }
  }
}

.hover-img {
  > .img-box {
    overflow: hidden;
    .img{
      transition: all @transition;
    }
  }
  &:hover{
    > .img-box {
      overflow: hidden;
      > .img {
        transform: scale(1.2);
      }
    }
  }
}
.hover-content-bg{
  transition: all @transition;
  &.item{
    &:hover{
      .content{
        color: #fff;
        background-color: @blue;
        .title{
          color: #fff;
        }
        .cu-btn{
          color: @blue;
          background-color: #fff;
        }
      }
    }
  }
}
.hover-line{
  > .img-box{
    position: relative;
    &::after{
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      height: 6px;
      width: 0%;
      background-color: @blue;
      transition: all @transition;
    }
  }
  &:hover{
    > .img-box{
      &::after{
        width: 100%;
      }
    }
  }
}
.hover-title-blue{
  &:hover{
    > .content{
      .title,.title-en{
        color: @blue;
      }
    }
  }
}
.hover-btn{
  > .content{
    .cu-btn{
      opacity: 0;
    }
  }
  &:hover{
    > .content{
      .cu-btn{
        opacity: 1;
      }
    }
  }
}

.pagination-box{
  width: 100%;
  justify-content: center;
  font-size: 16px;
  margin-top: 60px;
  margin-bottom: 60px;
  .prev{
    margin-right: 10px;
  }
  .next{
    margin-left: 24px;
    margin-right: 30px;
  }
  .n {
    width: 32px;
    height: 32px;
    margin-left: 20px;
    color: @black;
    .flex-center;
    border-radius: 3px;
    &.active,&:hover{
      background-color: @green;
      color: #fff;
    }
  }
}

.base-table{
  .table-r{
    font-size: 16px;
    color: @black;
    margin-bottom: 10px;
    > .item {
      .text-cut;
    }
    &.line{
      margin-bottom: 0;
      font-size: 14px;
      color: @light-black;
      padding: 16px 0;
      border-bottom: 1px solid @border;
    }
  }
}
.base-grid{
  background-color: #ffffff;
	box-shadow: 0px 2px 20px 0px 
    rgba(9, 9, 9, 0.24);
  width: 100%;
  padding: 40px;
  &.form-grid{
    padding: 36px 70px;
    padding-bottom: 60px;
  }
}
.no-more-data{
  width: 100%;
  color: @gray;
  margin-top: 40px;
  margin-bottom: 40px;
  text-align: center;
}
.qs-form {
  @form-field_width_mr: 10px;
  .btn-input {
    margin-top: 40px;
  }
  .tips-title {
    color: @gray;
    font-size: 14px;
    margin-top: 10px;
    margin-bottom: 16px;
  }
  .not-input-default {
    margin-bottom: 30px;
    font-size: 14px;
    color: @black;
    display: flex;
    > .label-title {
      margin-right: @form-field_width_mr;
      width: 100px;
      flex-shrink: 0;
      text-align: right;
      &.align-l{
        text-align: left;
      }
    }
    > .text {
      flex: 1;
      &.link {
        &:hover {
          color: @red;
        }
      }
    }
  }
  .select-default {
    width: 100px;
    height: 44px;
    color: @light-black;
    font-size: 14px;
  }
  .btn-group{
    margin-top: 50px;
  }
  .input-default,.form-group-inline,.form-group {
    display: flex;
    margin-bottom: 26px;
    .r-has-check{
      display: flex;
      align-items: center;
      > input[type="checkbox"]{
        flex: 0 0 18px;
        height: 18px;
        background-color: #eeeeee;
        border-radius: 2px;
        border: solid 1px #a3a3a3;
        margin-right: 10px;
      }
      > label.for-check{
        margin-top: 0;
        margin-right: 0;
        flex: none;
        width: auto;
      }
      .text_admire{
        font-size: 14px;
        color: @red;
        position: relative;
        cursor: pointer;
        &:after{
          content: '';
          position: absolute;
          left: 0;
          bottom: -2px;
          width: 100%;
          height: 1px;
          border-top: 1px dotted @red;
        }
      }
    }
    .form-field{
      flex: 1;
    }
    .tips-text {
      font-size: 14px;
      color: @gray;
    }
    label,.field-name {
      margin-right: @form-field_width_mr;
      font-size: 14px;
      color: @black;
      margin-top: 12px;
      width: 100px;
      flex-shrink: 0;
      text-align: right;
      &.align-l{
        text-align: left;
      }
    }
    > input,
    textarea,
    select,
    .input,.form-field input,.form-field select {
      width: 100%;
      border: none;
      padding: 0 13px;
      background-color: transparent;
      border: solid 1px #dbdbdb;
      // border-radius: 6px;
      color: @gray;
      height: 44px;
      &.sm-input{
        flex: 0 0 300px;
      }
      &.v-sm-input{
        width: 300px;
      }
    }
    .input-default-text{
      font-size: 14px;
      color: @black;
      margin-top: 12px;
      line-height: 1.5;
    }
    .right-input-box{
      flex: 1;
      textarea,input{
        width: 100%;
      }
      > .tips-text{
        position: static;
        margin-top: 4px;
      }
    }
    &.position {
      position: relative;
      .p-r-item {
        position: absolute;
        right: 0;
        top: 0;
      }
    }
    &.v{
      margin-bottom: 26px;
      .flex-direction;
      label,.title,.field-name{
        flex: 1;
        width: 100%;
        height: auto;
        line-height: 1.4;
        text-align: left;
        margin-bottom: 12px;
        margin-top: 0;
      }
      .input,.form-field{
        margin-left: 0;
      }
      .p-r-item{
        top: unset;
        bottom: 0;
        > .icon-refresh{
          margin-left: 10px;
        }
      }
    }
  }
  .avatar-wrap{
    .ossuploader-add, .ossuploader-dash-border{
      border-radius: 50%;
      width: 80px;
      height: 80px;
      margin-bottom: 0;
    }
    .ossuploader-add i{
      width: 20px;
      height: 20px;
    }
  }
  .not-margin {
    margin: 0;
  }
  .btn {
    &.btn-m {
      padding: 0;
      width: 130px;
    }
    & + .btn {
      margin-left: 30px;
    }
  }
}
/* ==================
          按钮
 ==================== */

 .cu-btn {
	position: relative;
	border: 0px;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	box-sizing: border-box;
	padding: 0 16px;
	font-size: 28px;
	height: 64px;
	line-height: 1;
	text-align: center;
	text-decoration: none;
	overflow: visible;
	margin-left: initial;
	transform: translate(0px, 0px);
	margin-right: initial;
}

.cu-btn::after {
	display: none;
}

.cu-btn:not([class*="bg-"]) {
	background-color: #f0f0f0;
}

.cu-btn[class*="line"] ,.cu-btn[class*="pure"]{
	background-color: transparent;
  border: 1px solid currentColor;
}

// .cu-btn[class*="line"]::after {
// 	content: " ";
// 	display: block;
// 	width: 200%;
// 	height: 200%;
// 	position: absolute;
// 	top: 0;
// 	left: 0;
// 	border: 1px solid currentColor;
// 	transform: scale(0.5);
// 	transform-origin: 0 0;
// 	box-sizing: border-box;
// 	border-radius: 2px;
// 	z-index: 1;
// 	pointer-events: none;
// }
.cu-btn[class*="pure"]::after {
	content: " ";
	display: block;
	width: 200%;
	height: 200%;
	position: absolute;
	top: 0;
	left: 0;
	border: 0px solid #e6e6e6;
	transform: scale(0.5);
	transform-origin: 0 0;
	box-sizing: border-box;
	z-index: 1;
	pointer-events: none;
}

.cu-btn.round[class*="line"]::after {
	border-radius: 1000px;
}

.cu-btn[class*="lines"]::after {
	border: 6px solid #e6e6e6;
}

.cu-btn[class*="bg-"]::after {
	display: none;
}
.cu-btn.direction{
  flex-direction: column;
  span {
    & + span{
      margin-top: 4px;
    }
  }
}
.cu-btn.direction-r{
  align-items: flex-end;
}
.cu-btn.direction-l{
  align-items: flex-start;
}
.cu-btn.h-44{
  height: 44px;
  font-size: 14px;
}
.cu-btn.h-48{
  height: 48px;
  font-size: 14px;
  padding: 0 16px;
}
.cu-btn.h-56{
  height: 56px;
  font-size: 16px;
}

.cu-btn.w-160{
  width: 160px;
  height: 44px;
  font-size: 14px;
}
.cu-btn.w-148{
  width: 148px;
  height: 48px;
  font-size: 14px;
  padding: 0 22px;
}
.cu-btn.w-186{
  width: 186px;
  height: 56px;
  font-size: 16px;
}
.cu-btn.w-180{
  width: 180px;
  height: 44px;
  font-size: 14px;
}
.cu-btn.sm {
	padding: 0 14px;
	font-size: 16px;
	height: 48px;
}
.cu-btn.qs-sm{
	padding: 0px 38px;
	font-size: 32px;
	height: 56px;
}
.cu-btn.qs-xs{
	padding: 0 22px;
	font-size: 24px;
	height: 42px;
}
.cu-btn.lg {
	padding: 0 40px;
	font-size: 32px;
	height: 80px;
}
.cu-btn.round{
	border-radius: 50px;
}
.cu-btn.s-round{
	border-radius: 3px;
}
.cu-btn.cuIcon.sm {
	width: 48px;
	height: 48px;
}



.cu-btn.cuIcon {
	width: 64px;
	height: 64px;
	border-radius: 500px;
	padding: 0;
}

button.cuIcon.lg {
	width: 80px;
	height: 80px;
}

.cu-btn.shadow-blur::before {
	top: 4px;
	left: 4px;
	filter: blur(6px);
	opacity: 0.6;
}

.cu-btn.button-hover {
	transform: translate(1px, 1px);
}
.cu-btn.block {
	width: 100%;
}

.cu-btn[disabled] {
	opacity: 0.6;
	color: #ffffff;
}

.module-title{
  text-align: center;
  width: 560px;
  margin: 0 auto;
  margin-top: 110px;
  margin-bottom: 60px;
  > h3{
    color: #000000;
    font-size: 36px;
    position: relative;
    padding-bottom: 14px;
    &::after{
      content: '';
      width: 24px;
	    height: 4px;
      display: inline-block;
      background-color: @blue;
      position: absolute;
      left: 50%;
      bottom: -1px;
      transform: translateX(-50%);
    }
    &::before{
      content: '';
      width: 100%;
	    height: 1px;
      display: inline-block;
      background-color: @border;
      position: absolute;
      left: 0%;
      bottom: 0;
    }
  }
  .en-title{
    font-size: 16px;
    color: @gray;
    margin-top: 18px;
  }
}
.h-module-title{
  .flex;
  padding-left: 14px;
  padding-bottom: 12px;
  border-bottom: 1px solid @border;
  margin-bottom: 24px;
  align-items: flex-end;
  justify-content: space-between;
  .zh{
    position: relative;
    font-size: 20px;
    color: #1a1a1a;
    margin-right: 10px;
    &::before{
      position: absolute;
      left: -14px;
      top: 50%;
      width: 4px;
      height: 18px;
      background-color: @blue;
      transform: translateY(-50%);
      content: '';
      display: inline-block;
    }
  }
  .en{ 
    font-size: 16px;
    color: @gray; 
  }
  .r{
    .flex;
    align-items: center;
    .tips-text{
      font-size: 14px;
      color: #4d4d4d;
      margin-left: 6px;
    }
  }
  &.sidebar{
    border-bottom: none;
    margin-bottom: 0;
  }
}
.mt70{
  margin-top: 70px;
}
.mt4{
  margin-top: 4px;
}
.mt25{
  margin-top: 25px;
}
.mr50{
  margin-right: 50px;
}
.no-data{
  margin-top: 60px;
  margin-bottom: 60px;
  text-align: center;
  color: @gray;
}
.pagination-box{
  width: 100%;
  justify-content: center;
  font-size: 16px;
  margin-top: 60px;
  margin-bottom: 60px;
  .prev{
    margin-right: 10px;
  }
  .next{
    margin-left: 24px;
    margin-right: 30px;
  }
  .n {
    width: 30px;
    height: 30px;
    margin-left: 20px;
    color: @black;
    .flex-center;
    border-radius: 3px;
    &.active,&:hover{
      background-color: @blue;
      color: #fff;
    }
  }
}
body{
  a.hover-white{
    color: #fff;
  }
}
.qs-popup-wrapper{
	padding: 44px;
	width: 520px;
	background-color: #ffffff;
	border-radius: 6px;
	.icon-close{
    z-index: 9;
		position: absolute;
		top: -40px;
		right: 0;
	}
	.popup-header{
		padding: 0;
    text-align: center;
    margin-bottom: 28px;
    > .en-title{
      justify-content: center;
      font-size: 18px;
      color: #1a1a1a;
      display: flex;
      align-items: flex-end;
      > span{
        margin-left: 10px;
        font-size: 14px;
        color: @gray;
      }
    }
	}
	.popup-content{
		margin: 0;
		padding: 0;
    max-height: 400px;
  }
  .btn-group{
    .icon-close{
      position: static;
    }
  }
  &.w-550{
    width: 550px;
  }
}
.en-title-wrap{
    display: flex;
    align-items: flex-end;
    color: #ffffff;
    font-size: 20px;
    > .en{
      font-size: 14px;
      margin-left: 5px;
    }
  }