@import "./var";

.iconGen(@icon,@width:10px,@height:10px){
  background: url("@{icon_url}@{icon}.png") no-repeat center;
  width: @width;
  height: @height;
  background-origin:content-box;
  background-size: contain;
  display: inline-block;
}

.icon-close{
  .iconGen('icon-close',30px,30px);
}
.icon-down{
  .iconGen('icon-down',12px,7px);
}
.icon-top{
  .iconGen('icon-top',12px,7px);
}
.icon-refresh{
  .iconGen('icon-refresh',20px,19px);
}
.icon-success{
  .iconGen('icon-success',40px,40px);
}
.icon-b-left{
  .iconGen('icon-left',8px,14px);
}
.icon-b-right{
  .iconGen('icon-right',8px,14px);
}
.icon-time{
  .iconGen('icon-time',18px,18px);
}
.icon-y-phone{
  .iconGen('icon-y-phone',16px,14px);
}
.icon-y-city{
  .iconGen('icon-y-city',16px,19px);
}
.icon-y-email{
  .iconGen('icon-y-email',18px,14px);
}
.icon-edit{
  .iconGen('icon-edit',18px,18px);
  .as-a;
}
.icon-w-edit{
  .iconGen('icon-w-edit',18px,18px);
}
.icon-delete{
  .iconGen('icon-delete',19px,19px);
  .as-a;
}
.icon-g-wechat{
  .iconGen('g-wechat',37px,32px);
}
.icon-Wechat{
  .iconGen('g-wechat',37px,32px);
}
.icon-Alipay{
  .iconGen('g-alipay',36px,36px);
}
.icon-Transfer{
  .iconGen('g-pay',50px,34px);
}
.icon-y-question{
  .iconGen('icon-y-question',16px,16px);
}
.icon-address{
  .iconGen('icon-address',15px,15px);
}
.icon-persons{
  .iconGen('icon-persons',15px,14px);
}
